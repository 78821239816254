import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ScrollToTop from "../ScrollToTop";
import Navigation, { NavButton } from "../Navigation";
import HomePage from "../Home";
import DilfridgePage from "../Dilfridge";
import DynacoolPage from "../Dynacool";
import SquidPage from "../Squid";
import ToggleSwitch from "../Toggle";

import * as ROUTES from "../../constants/routes";

import "./index.css";

const INITIAL_STATE = {
  jargon: false,
  showNav: false
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.closeNav = this.closeNav.bind(this);
    this.toggleJargon = this.toggleJargon.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
  }

  closeNav() {
    this.setState({ showNav: false });
  }

  toggleJargon(e) {
    this.setState({ jargon: !this.state.jargon });
  }

  toggleNav(e) {
    this.setState({ showNav: !this.state.showNav });
  }

  render() {
    const { showNav, jargon } = this.state;

    const toggleLbl = jargon ? (
      <p id="ToggleLbl">Toggle Tech Jargon Off</p>
    ) : (
      <p id="ToggleLbl">Toggle Tech Jargon On</p>
    );

    return (
      <Router>
        <ScrollToTop>
          <div className="App">
            <header>
              <NavButton handleClick={this.toggleNav} />
              {toggleLbl}
              <ToggleSwitch handleChange={this.toggleJargon} />
            </header>

            {showNav ? <Navigation closeNav={this.closeNav} /> : null}

            <Route
              path={ROUTES.DILFRIDGE}
              render={() => (
                <DilfridgePage
                  {...this.state}
                  showModal={this.showModal}
                  hideModal={this.hideModal}
                />
              )}
            />
            <Route
              path={ROUTES.DYNACOOL}
              render={() => (
                <DynacoolPage
                  {...this.state}
                  showModal={this.showModal}
                  hideModal={this.hideModal}
                />
              )}
            />
            <Route
              path={ROUTES.SQUID}
              render={() => <SquidPage {...this.state} />}
            />
            <Route
              exact
              path={ROUTES.HOME}
              render={() => <HomePage {...this.state} />}
            />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
