import React from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import "./index.css";

import dilFridge from "../../assets/minDilFridge.png";
import ppms from "../../assets/ppms-trimmed.png";
import mpms3 from "../../assets/mpms3.png";

const mrl_logo =
  "https://www.mrl.ucsb.edu/sites/default/files/layout_images/mrl_logo.png";
const ucsb_logo =
  "https://www.mrl.ucsb.edu/sites/default/files/layout_images/ucsb-logo.gif";
const mrsec_logo =
  "https://www.mrl.ucsb.edu/sites/default/files/layout_images/mrsec-logo.jpg";

const HomePage = props => (
  <div>
    <img id="mrlLogo" src={mrl_logo} alt="The MRL Logo" />
    <h2 id="homeTitle" className="contentTitle">
      The Low-Temperature Lab
    </h2>
    <h3 className="subTitle">
      <em>a.k.a The Fish Bowl</em>
    </h3>

    {props.jargon ? (
      <div className="contentContainer">
        <Header />
        <Footer />
      </div>
    ) : (
      <div className="contentContainer">
        <Header />

        <h4>Thermal, Electronic and Magnetic Properties Analysis</h4>
        <content>
          {/**<img src={dilFridge} alt="A Helium Dilution Refrigerator" id="dilFridge"/>*/}
          <p>
            The Low-Temperature Lab is designed for testing samples and
            materials under a range of conditions. Instruments in this lab can
            operate at temperatures of anywhere from 1000 Kelvin (727 °C) to
            below 30 millikelvin (-273 °C). The systems also use some impressive
            superconducting magnets that can generate fields of up to 14 Tesla.
          </p>
          <p>
            Our researchers come from all over and are actively working on a
            vast array of different projects and ideas. Some of our studies are
            currently working on
            <strong> Perovskites</strong>,<strong> Semiconductors</strong>,
            <strong> Solid State Physics</strong>,
            <strong> Magnetocalorics</strong>,
            <strong> Topological Materials</strong>, and many more.
          </p>
        </content>

        <Footer />
      </div>
    )}
  </div>
);

const Header = () => (
  <div>
    <h4>A QR Guided Tour</h4>
    <content>
      <p>
        Welcome to the{" "}
        <strong>
          QR Guided Tour of the Low Temperature characterization facility
        </strong>
        . Lab. At each instrument is a QR code you can scan with your phone to
        learn more about the machine. The menu button for this website is on the
        top left of this page. If you really want to get more technical you can
        also click the slider at the top right.
      </p>
    </content>
  </div>
);

const Footer = () => (
  <div>
    <h4>The Instruments</h4>
    <content>
      <p>
        We have three heavy-hitters in the Low-Temperature lab. You can click on
        their pictures to explore them or use the menu on the top.
      </p>

      <p className="linkContainer">
        <Link to={ROUTES.DYNACOOL}>
          <img src={ppms} alt="The DynaCool PPMS" id="ppms-Home" />
        </Link>
        <Link to={ROUTES.DILFRIDGE}>
          <img
            src={dilFridge}
            alt="The Helium Dilution Refrigerator"
            id="dilFridge-Home"
          />
        </Link>
        <Link to={ROUTES.SQUID}>
          <img src={mpms3} alt="The MPMS3 Squid" id="mpms3-Home" />
        </Link>
      </p>
    </content>

    <h4>A Part of TEMPO Labs</h4>
    <content>
      <p>
        This room is part of TEMPO labs, a facility in the
        <strong> Materials Resarch Laboratory</strong> at UCSB. TEMPO provides
        advanced instrumentation for the characterization of materials and
        chemical samples. Our systems are used to analyze a wide range of
        properties such as
        <strong> T</strong>hermal,<strong> E</strong>lectronic/
        <strong>E</strong>lemental,<strong> M</strong>agnetic,
        <strong> P</strong>orosity and
        <strong> O</strong>ptical.
      </p>

      <p className="logoContainer">
        <img src={ucsb_logo} alt="UCSB Logo" id="ucsbLogo" className="logo" />
        <img
          src={mrsec_logo}
          alt="MRSEC Logo"
          id="mrsecLogo"
          className="logo"
        />
      </p>
    </content>
  </div>
);

export default HomePage;
