import React from "react";

import dilFridge from "../../assets/minDilFridge.png";
import samplePuck from "../../assets/drSamplePuck.png";
import dilFridgeDiagram from "../../assets/dfDiagram.png";

import "./index.css";

const phaseDiagramSrc =
  "https://upload.wikimedia.org/wikipedia/commons/a/a6/Helium_phase_diagram.svg";

const dilFridgeGuideLink =
  "http://www.roma1.infn.it/exp/cuore/pdfnew/Fridge.pdf";
const wikiJouleThompLink =
  "https://en.wikipedia.org/wiki/Joule%E2%80%93Thomson_effect";
const wikiIsenthalpicLink = "https://en.wikipedia.org/wiki/Isenthalpic_process";

const DilfridgePage = props => (
  <div>
    <h2 className="contentTitle">The Helium-3 Dilution Refrigerator</h2>

    {props.jargon ? <Technical /> : <NonTechnical />}
  </div>
);

const Technical = () => (
  <div className="contentContainer">
    <h4>Oxford Instruments' Triton™</h4>
    <content>
      <img
        src={phaseDiagramSrc}
        id="phaseDiagram"
        alt="A Diagram of the phase breakdown between helium-3 and helium-4 as a function of temperature and helium-3 concentration"
      />
      <p>
        The Triton™ is a cryogen-free dilution refrigerator that exploits the
        spontaneous separation of{" "}
        <strong>
          <sup>3</sup>He
        </strong>{" "}
        and{" "}
        <strong>
          <sup>4</sup>He
        </strong>{" "}
        isotope mixtures at temperatures below 870 millikelvin.
      </p>

      <p>
        Capable of achieving and maintaing a <strong>20 milliKelvin</strong>{" "}
        environment while employing a <strong>14 Tesla</strong> magnetic field,
        the instrument is used to observe quantum surface states in ultra-thin
        films.
      </p>

      <p>
        Dilution Refrigerator designs can vary. The original concept, a{" "}
        <strong>wet dilution refrigerator</strong>, relies on the use of liquid
        nitrogen for a pre-cooling system while our{" "}
        <strong>dry dilution refrigerator</strong> uses a double-stage pulse
        tube pre-cooling circuit and compressor to reach 5 K.
      </p>
    </content>

    <h4>
      The <sup>3</sup>He and <sup>4</sup>He cooling cycle
    </h4>
    <content>
      <p>
        Once the instrument has cooled down to 5 K a high vacuum is established
        thermally isolating the internal chamber. Inside this chamber the helium
        gas mixture undergoes a controlled isenthalpic process cooling it to
        below 1.5 K and causing the gases to condense.
      </p>
      <img
        src={dilFridgeDiagram}
        id="dilFridgeDiagram"
        alt="A diagram of the Helium-3 dilution of Helium-4 and it's distillation."
      />
      <p>
        The now liquid mixture flows through heat exchangers into the mixing
        chamber where an <strong>equilibrium</strong> between two distinct
        phases form. The top layer is composed of theoretically pure{" "}
        <sup>3</sup>He and the bottom holds a ratio of approximately 6.6%{" "}
        <sup>3</sup>He and 93.4% <sup>4</sup>He.
      </p>

      <p>
        A portion of this heavier phase is cycled into the still, through a
        series of heat exchangers which cools . At 0.6 K the <sup>3</sup>He is
        preferentially evaporated off and recycled through the instrument.
      </p>

      <p>
        Continuous evaporation of <sup>3</sup>He ensures that the dilute phase
        is unsaturated, allowing for more of the lighter isotope to cross the{" "}
        <strong>phase boundary</strong>.
      </p>

      <p>
        As the <sup>3</sup>He crosses from the concentrated phase into the
        dilute phase it consumes a quantity of heat from the environment.
        Repeated cycles of evaporation and dilution drive the cooling mechanism
        for the system, at extremely low temperatures.
      </p>
    </content>

    <h4>Still curious?</h4>
    <content>
      <p>
        Here are a few of our research publications that have used this
        instrument!
      </p>
    </content>
    <content className="papers-container">
      <a href="https://doi.org/10.1103/PhysRevLett.120.016801">
        Observation of the Quantum Hall Effect in Confined Films of the
        Three-Dimensional Dirac Semimetal Cd<sub>3</sub>As<sub>2</sub>
      </a>
      <a href="https://doi.org/10.1063/1.5016866">
        Thickness dependence of the quantum Hall effect in films of the
        three-dimensional Dirac semimetal Cd<sub>3</sub>As<sub>2</sub>
      </a>
    </content>
  </div>
);

const NonTechnical = () => (
  <div className="contentContainer">
    <h4>Oxford Instruments' Triton™ Dilution Refrigerator</h4>
    <content>
      <img
        src={dilFridge}
        id="dilFridge"
        alt="The Helium-3 Dilution Refrigerator"
      />

      <p>
        The Triton Dilution Refrigerator (DR) is an impressive instrument
        capable of reaching the extraordinarily low temperature of{" "}
        <strong>10 mK</strong> (-459.652 °F, -273.14 °C).
      </p>

      <p>
        In addition to its impressive cooling range the instrument also contains
        a superconducting magnet capable of a <strong>14 Tesla</strong> or
        140,000 gauss field making it a sophisticated environmental chamber
        capable of generating some of the most extreme conditions achievable in
        a lab setting.
      </p>

      <p>
        Despite its capabilities the system is not a high-throughput instrument
        and takes about 12 hours to cool a sample down to 10 mK.
      </p>
    </content>

    <h4>How it works</h4>
    <content>
      <p>
        There are no built-in measurement options on the system as it is
        designed only to provide the environment. Users must have a good
        background in cryogenic systems as well as electrical measurements to
        effectively use it.
      </p>
      <p>
        Samples are mounted on a circuit board designed in house by one of our
        researchers. Wires connect the samples to contact pads on the board.
      </p>

      <img
        src={samplePuck}
        id="samplePuckMount"
        alt="The sample puck mount for the Dilution Refrigerator"
      />

      <p>
        The board is then placed in and wired to the{" "}
        <strong>sample puck</strong> as shown to the right. When the puck is
        loaded into the instrument the electrical channels will allow the
        control and measurement of current flow through the sample.
      </p>
    </content>

    <h4>But how does it get so cold?</h4>
    <content>
      <p>
        There are different types of dilution refrigerators with varying
        methods. Oxford's cryogen-free system first uses a{" "}
        <strong>pre-cooling circuit</strong> composed of a high and low pressure
        Helium-3 loop connected to two pulse tube stages and compressor which
        will bring the internal components to a temperature of 5 K.
      </p>

      <p>
        Next a <strong>Joule-Thompson stage</strong> is employed, cooling the
        gas to below 1.5 K and causing it to condense. The condensed liquid then
        flows into the dilution unit.
      </p>
    </content>
    <img
      src={dilFridgeDiagram}
      id="dilFridgeDiagram"
      alt="A diagram of the Helium-3 dilution of Helium-4 and it's distillation."
    />

    <h4>The Dilution Unit</h4>
    <content>
      <p>
        True to the system's name this component uses a mixture of two helium{" "}
        <strong>isotopes</strong> to provide extra cooling power. When mixed at
        temperatures below 870 mK the
        <strong>
          <sup>3</sup>He
        </strong>{" "}
        and the heavier{" "}
        <strong>
          <sup>4</sup>He
        </strong>{" "}
        will spontaneously separate.
      </p>
      <p>
        Separation forms two distinct phases, much like mixing oil and water.
        Pure <sup>3</sup>He sits on the top and a layer of mostly <sup>4</sup>He
        sits at the bottom.
      </p>
      <p>
        When small amounts of <sup>3</sup>He move into the lower layer,{" "}
        <strong>diluting</strong> the mixture, a small amount of energy is
        consumed. The act of dilution absorbs heat from the environment and
        cools the helium mixture.
      </p>

      <p>
        A portion of this diluted layer is sent to the still where <sup>3</sup>
        He is preferentially evaporated off and recycled through the instrument
        before flowing into the mixing chamber again.
      </p>
    </content>
    <h4>Still curious?</h4>
    <content>
      <p>Try some of these links or flip the "tech jargon" switch up top!</p>
      <p className="linkContainer">
        <a href={dilFridgeGuideLink}>
          Hitchhiker’s Guide to the Dilution Refrigerator
        </a>
        <a href={wikiJouleThompLink}>Joule-Thomson effect</a>
        <a href={wikiIsenthalpicLink}>Isenthalpic processes</a>
      </p>
    </content>
  </div>
);

export default DilfridgePage;
