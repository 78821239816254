import React from 'react';

import './index.css';

const ToggleSwitch = (props) => {
    return (
        <label className="switch">
            <input type="checkbox" onChange={props.handleChange} />
            <span className="slider round"></span>
        </label>
    )
}

export default ToggleSwitch;