import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from './navButton';

import * as ROUTES from '../../constants/routes';

import './index.css';

const Navigation = (props) => (
    <div id="navMenu">
        <i className="fas fa-times" onClick={(e)=>props.closeNav()}></i>
        <Link to={ROUTES.HOME} onClick={(e)=>props.closeNav()}>
            Home
        </Link>
        <Link to={ROUTES.DYNACOOL} onClick={(e)=>props.closeNav()}>
           Dynacool PPMS
        </Link>
        <Link to={ROUTES.SQUID} onClick={(e)=>props.closeNav()}>
            SQUID MPMS
        </Link>
        <Link to={ROUTES.DILFRIDGE} onClick={(e)=>props.closeNav()}>
            Helium Dilution Refrigerator
        </Link>
        <a href="https://www.mrl.ucsb.edu/">MRL Homepage</a>
    </div>
)

export default Navigation;

export { NavButton };