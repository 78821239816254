import React from "react";

import mpms3 from "../../assets/mpms3.png";
import mpms3Cutaway from "../../assets/mpms3_cutaway.png";
import sampleRod from "../../assets/mpms3_sampleRod_vertical_labels.png";
import vsm from "../../assets/vsmOption.png";
import sampleRodCutoff from "../../assets/mpms3_sampleRod_cutoff.png";
import sampleProbe from "../../assets/mpms3_sampleProbe_cropped.png";

import "./index.css";

const josephsonJuncLink =
  "https://www.scientificamerican.com/article/what-are-josephson-juncti/";
const SquidLink =
  "http://hyperphysics.phy-astr.gsu.edu/hbase/Solids/Squid.html";
const wikiElectroMagnetGen = "https://en.wikipedia.org/wiki/Electric_generator";

const SquidPage = props => (
  <div>
    <h2 className="contentTitle">The MPMS3 Squid®</h2>

    {props.jargon ? <Technical /> : <NonTechnical />}
  </div>
);

const Technical = () => (
  <div className="contentContainer">
    <h4>A Magnetic Property Management System</h4>
    <content>
      <img src={mpms3} id="mpms3" alt="The MPMS3 instrument" />

      <p>
        Quantum Design's Magnetic Property Measurement System (MPMS) is a suite
        of analytical instruments designed to study the magnetic properties of
        experimental samples. Measurements can be automated and performed across
        a range of temperature and magnetic field strengths.
      </p>

      <p>
        Although this and the DynaCool™ share some functional overlap the MPMS
        instrument can obtain higher resolution measurements using a
        <strong> Superconducting Quantum Interference Device</strong> (SQUID) in
        combination with superconducting pickup coils. The system is also
        capable of much faster field charging and temperature cooling rates.
      </p>
    </content>

    <h4>Measurements:</h4>
    <content>
      <p>
        Experimental samples are first mounted and centered in a{" "}
        <strong>magnetically neutral</strong> medium. High quality materials,
        such as quartz tubing, are available but these are often brittle or
        fragile. For less sensitive measurements a more robust approach utilizes
        drinking straws that have been tested for magnetic impurities.
      </p>
      <img
        src={sampleRodCutoff}
        id="sampleRodCutoff"
        alt="The sample rod connector and straw adaptor."
      />
      <p>
        Once mounted the sample holder is sealed and connected to a rod before
        being lowered into the sample probe.
      </p>
    </content>

    <h4>How it works:</h4>
    <content>
      <img
        src={sampleProbe}
        id="sampleProbe"
        alt="A diagram of the sample probe inside of the instrument."
      />
      <p>
        When a measurement begins the linear transport motor, which sits atop
        the sample probe, begins driving the sample up and down through a region
        of the chamber surrounded by super conducting coils.
      </p>

      <p>
        As a magnetic sample moves up and down through the detection coils it
        creates <strong>magnetic flux</strong> and induces an electrical
        current.
      </p>

      <p>
        The detection coils form a closed loop with the <strong>SQUID</strong>{" "}
        component. The induction of a current across this closed circuit, in
        response to the sample, is proportional to the magnetic flux.
      </p>
    </content>
    <h4>The SQUID's Role</h4>
    <content>
      <p>
        The SQUID functions as a highly linear current-to-voltage convertor
        through the use of two parallel <strong>Josephson junctions</strong>{" "}
        separated by thin insulating layers.
      </p>

      <img
        src="https://www.qdusa.com/images/products/SQUID-3.jpg"
        id="SQUID"
        alt="The SQUID circuit"
      />

      <p>
        The device itself built on a small chip and seated at the bottom of the
        sample probe. This circuit is highly sensitive to magnetic field
        fluctuations and must be heavily shielded to reduce noise.
      </p>
    </content>

    <h4>Specifications:</h4>
    <content>
      <table>
        <tbody>
          <tr>
            <th>Temperature Control:</th>
          </tr>
          <tr>
            <td>Range:</td>
            <td>1.8 to 400 K Standard; 1.8 to 1000 K with Oven option</td>
          </tr>
          <tr>
            <td>Cooling Rate:</td>
            <td>30 K/min (300 to 10 K in 15 minutes)</td>
          </tr>
          <tr>
            <td />
            <td>10 K/min (10 to 1.8 K in 5 minutes)</td>
          </tr>
          <tr>
            <th>Magnet Control:</th>
          </tr>
          <tr>
            <td>Range:</td>
            <td>-70 kOe to +70 kOe</td>
          </tr>
          <tr>
            <td>Charging Rate:</td>
            <td>4 Oe/sec to 700 Oe/sec</td>
          </tr>

          <tr>
            <td>Uniformity:</td>
            <td>±0.01% over 4 cm on axis</td>
          </tr>
          <tr>
            <td>Charging Resolution:</td>
            <td>0.33 Oe</td>
          </tr>
          <tr>
            <th>Measurements:</th>
          </tr>
          <tr>
            <td>Maximum DC moment:</td>
            <td>10 emu</td>
          </tr>
          <tr>
            <td>
              Sensitivity <br />
              (≤ 2,500 Oe):
            </td>
            <td>≤ 5 x 10-8 emu (DC scan)</td>
          </tr>
          <tr>
            <td />
            <td>≤ 1 x 10-8 emu (VSM, less than 10 second averaging)*</td>
          </tr>
          <tr>
            <td>
              Sensitivity <br />
              (> 2,500 Oe):
            </td>
            <td>≤ 6 x 10-7 emu (DC scan)</td>
          </tr>
          <tr>
            <td />
            <td>≤ 8 x 10-8 emu (VSM, less than 10 second averaging)*</td>
          </tr>
          <tr>
            <td>Variable drive amplitude:</td>
            <td>0.1 to 8 mm (peak)</td>
          </tr>
          <tr>
            <th>General Details:</th>
          </tr>
          <tr>
            <td>Measurement Options:</td>
            <td>VSM EverCool, VSM Oven (1000 K), AC option</td>
          </tr>
          <tr>
            <td>Sample Chamber Size:</td>
            <td>Inner Diameter of 9 mm</td>
          </tr>
        </tbody>
      </table>
    </content>
    <h4>Still curious?</h4>
    <content>
      <p>
        Here are a few of our research publications that have used this
        instrument!
      </p>
    </content>
    <content className="papers-container">
      <a href="https://journals.aps.org/prmaterials/abstract/10.1103/PhysRevMaterials.3.014402">
        Deciphering structural and magnetic disorder in the chiral skyrmion host
        materials Co<sub>x</sub>Zn<sub>y</sub>Mn<sub>z</sub> (x+y+z=20)
      </a>
      <a href="https://arxiv.org/abs/1902.05937">
        High-Pressure Laser Floating Zone Furnace
      </a>
      <a href="https://aip.scitation.org/doi/figure/10.1063/1.5030341">
        Low magnetic damping and large negative anisotropic magnetoresistance in
        half-metallic Co<sub>2-x</sub>Mn<sub>1+x</sub>Si Heusler alloy films
        grown by molecular beam epitaxy{" "}
      </a>
    </content>
  </div>
);

const NonTechnical = () => (
  <div className="contentContainer">
    <h4>A Magnetic Property Management System</h4>
    <content>
      <img src={mpms3} id="mpms3" alt="The MPMS instrument" />

      <p>
        Our Magnetic Property Measurement System (MPMS) is also designed by
        Quantum Design and employs a{" "}
        <strong>Superconducting Quantum Interference Device</strong> (SQUID) to
        measure the magnetic properties of experimental samples under a range of
        temperatures and magnetic fields.
      </p>

      <p>
        Similar to the DynaCool™ it can reach temperatures of{" "}
        <strong>400 K</strong> (260.33 °F, 127 °C) down to{" "}
        <strong>1.8 K</strong> (-456.43 °F, -271 °C).
      </p>

      <p>
        The system can produce up to a <strong>7 Tesla (70 kOe)</strong>{" "}
        magnitude magnetic field and although this isn't as powerful as the
        DynaCool the MPMS3 can stabilize it much more rapidly. With a field
        charging rate of <strong>4 to 700 Oe/sec</strong> the instrument can
        reach max field in 100 seconds or less!
      </p>
    </content>
    <h4>How it works</h4>

    <img
      src={mpms3Cutaway}
      id="mpms3Cutaway"
      alt="A cutaway of the MPMS3 system showing the internals"
    />

    <content>
      <p>
        Unlike the DynaCool, samples in the MPMS3 don't need to be wired to an
        electrical channel making sample pucks unnecessary but to ensure that
        the signal we measure is only coming from our sample we have to be
        careful not to introduce magnetic impurities.
      </p>

      <img
        src={sampleRod}
        id="sampleRod"
        alt="A diagram of the sample rod attaching to the sample straw."
      />

      <p>
        These impurities can be our fingerprints (diamagnetic), paramagnetic
        metals, or most commonly, iron impurities (ferromagnetic) which are
        almost everywhere. To find a magnetically clean sample holder we look no
        farther than a common drinking straw.
      </p>

      <p>
        These aren't just any straws! They're special... sort of. They do look
        just like drinking straws you'll find at a restaurant but they're
        guaranteed to be <strong>magnetically neutral</strong>.
      </p>
    </content>
    <h4>Measurements</h4>
    <content>
      <p>
        To perform a measurement the sample is attached to a long rod on a
        motor. The motor moves the rod up and down through a set of detection
        coils.
      </p>

      <p>
        You can envision this as an <strong>electromagnetic generator</strong>{" "}
        where a magnet is moving through a set of coils to generate a current,
        but in our case the magnet is a sample and the current depends on where
        the sample is within the set of coils.
      </p>

      <p>
        That current is detected by the SQUID which converts it in to a
        position-dependent voltage.
      </p>

      <div id="vsmLookFor-container">
        <div id="vsm-container">
          <img src={vsm} id="vsm" alt="The Linear Transport Motor" />
          <div id="motorDot" />
        </div>
        <h5>
          Look for: A gold dot in the window of the linear transport motor. This
          dot moves with the sample rod and can show if a test is being run as
          well as how fast the motor is driving the sample up and down.
        </h5>
      </div>
    </content>
    <h4>Great... but what's a SQUID?</h4>
    <content>
      <p>
        The SQUID itself is actually a very small piece of the instrument but it
        is the most important part and naturally is very complicated. A
        simplified description is a superconducting circuit with one or two{" "}
        <strong>
          Josephson junctions (more about these in the links below)
        </strong>{" "}
        and is electrically connected to the detection coils.
      </p>
      <p>
        The SQUID essentially detects the tiny current generated by our sample's
        movement and produces a proportional voltage. The voltage signal is used
        as a function of the samples position in the detection coils which
        provides the data we use to determine the magnetism of the sample.
      </p>
    </content>
    <h4>Still curious?</h4>
    <content>
      <p>Try some of these links or flip the "tech jargon" switch up top!</p>
      <p className="linkContainer">
        <a href={wikiElectroMagnetGen}>Electromagnetic Generators</a>
        <a href={josephsonJuncLink}>Josephson Junctions</a>
        <a href={SquidLink}>SQUIDs</a>
      </p>
    </content>
  </div>
);

export default SquidPage;
