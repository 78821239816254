import React from "react";

import ppms from "../../assets/ppms.png";
import ppmsSampleChamber_noLabels from "../../assets/ppms_sampleChamber2.png";
import vsmOption from "../../assets/vsmOption.png";

import "./index.css";

const pulseTubeLink = "https://en.wikipedia.org/wiki/Pulse_tube_refrigerator";
const dynacoolMRLLink =
  "http://www.mrl.ucsb.edu/tempo-facility/instruments/dynacool-ppms";
const superMagnetsLink = "https://en.wikipedia.org/wiki/Superconducting_magnet";

const DynacoolPage = props => (
  <div>
    <h2 className="contentTitle">The DynaCool™ PPMS®</h2>
    {props.jargon ? <Technical /> : <NonTechnical />}
  </div>
);

const Technical = () => (
  <div className="contentContainer">
    <h4>A Physical Property Management System</h4>
    <content>
      <img src={ppms} id="ppms" alt="The PPMS instrument" />

      <p>
        Quantum Design's DynaCool™ PPMS® performs electrical, thermal and
        magnetic measurements while controlling the samples temperature and
        magnetic environment.
      </p>

      <p>
        The system uses a single two-stage pulse tube cooler to cool both the
        superconducting magnet and provide temperature control below 10K,
        resulting in a low vibration environment for sample measurements.
      </p>

      <p>
        At low temperatures a small amount of Helium-4 is condensed and used to
        cool the sample while the magnet is cooled through solid contact to a 4
        K plate.
      </p>
    </content>

    <h4>Specifications:</h4>
    <content>
      <table>
        <tbody>
          <tr>
            <th>Temperature Control:</th>
          </tr>
          <tr>
            <td>Range:</td>
            <td>1.8 to 400 K @ 60HZ; 1.85 to 400 K @ 50Hz</td>
          </tr>
          <tr>
            <td>Sample Cool Down Time:</td>
            <td>300 to 1.9 K (stable) in 40 minutes (typical)</td>
          </tr>
          <tr>
            <th>Magnet Control:</th>
          </tr>
          <tr>
            <td>Range & Orientation:</td>
            <td>0 to 9 Tesla, Longitudinal</td>
          </tr>
          <tr>
            <td>Control Modes:</td>
            <td>Linear, Oscillating, No Overshoot</td>
          </tr>
          <tr>
            <td>Time to Full Field:</td>
            <td>Less than 8 minutes (typical)</td>
          </tr>
          <tr>
            <td>Field Uniformity:</td>
            <td>±0.01% over 3 cm on axis</td>
          </tr>
          <tr>
            <th>General Details:</th>
          </tr>
          <tr>
            <td>Measurement Options:</td>
            <td>Resistivity, ETO, Horizontal Rotator & VSM</td>
          </tr>
          <tr>
            <td>Sample Chamber Size:</td>
            <td>Clear bore 2.5 cm (1 inch) diameter</td>
          </tr>
        </tbody>
      </table>
    </content>

    <h4>Measurements</h4>
    <content>
      <img
        src={ppmsSampleChamber_noLabels}
        id="ppms-sampleChamber"
        alt="A diagram of the sample chamber and sample puck."
      />
      <p>
        Samples are mounted and wired to a sample puck which is then placed at
        the bottom of the narrow sample chamber. The sample puck is keyed to sit
        in a specific orientation so that each electrical pin enters the correct
        channel.
      </p>

      <p>
        When a test is performed the instrument will run a current through these
        channels and the sample while measuring the voltage across it. Some
        measurement types will require different wire connections between the
        sample and the puck.
      </p>
    </content>

    <h4>Electrical Transport Option (ETO)</h4>
    <content>
      <p>
        The ETO option uses a 2-channel measurement system with AC lock-in
        voltage to measure electrical resistance or Hall effects. Resistance can
        be measured from <strong>1 µΩ - 10 MΩ</strong> using a four wire setup
        or from <strong>10 MΩ - 5 GΩ</strong> using a two wire connection. Its
        built in functions are (AC) Resistivity, IV Curve, and dV/dI.
      </p>
    </content>

    <h4>Resistivity</h4>
    <content>
      <p>
        The resistivity option is very similar to ETO but has 3 channels, a
        smaller measurement range (10 µΩ to 1 MΩ) and a lower maximum excitation
        current (5 mA) but for researchers measuring Hall voltages on
        irregularly-shaped samples, it is the only option that allows for{" "}
        <strong>Van der Pauw wiring</strong>.
      </p>
    </content>

    <h4>Horizontal Rotator Option</h4>
    <content>
      <p>
        The Horizonal Rotator option allows samples to be measured at any angle
        with respect to the magnetic field. It does not do measurements by
        itself but may be used with either the ETO or resisitivity options.
      </p>
    </content>

    <h4>Thermal Transport Option (TTO)</h4>
    <content>
      <p>
        The Thermal Transport option measures{" "}
        <strong>thermal conductivity</strong>,{" "}
        <strong>Seebeck coefficient</strong>,{" "}
        <strong>electrical resistivity</strong> and the thermoelectric{" "}
        <strong>Figure of Merit</strong>. Heat loss is measured between two
        thermometers attached to the sample, following an applied heat pulse and
        the data is used to determine the thermal conductivity.
      </p>

      <p>
        If the voltage across the sample is monitored we can report the Seebeck
        coefficient. The electrical (AC) resistivity is also measured and in
        combination with the thermal conductivity and Seebeck, the
        thermoelectric Figure of Merit may be calculated.
      </p>
    </content>

    <h4>Heat Capacity Option (HCO)</h4>
    <content>
      <p>
        In the Heat Capacity option the sample sits on a small platform with an
        embedded heater and thermometer. Electrical leads connect the platform
        to the puck and a thermal shield and extra thermometer provide accurate
        and stable temperature readings.
      </p>
      <p>
        The sample must be in close contact with the platform as heat is applied
        to the platform and transferred to the sample. When we stop applying
        power the puck acts as a heat sink and the time it takes for the sample
        to relax back down to the puck temperature is used to calculate the heat
        capacity.
      </p>
    </content>

    <h4>Vibration Sample Magnetometer Option (VSM)</h4>
    <img src={vsmOption} id="vsmOption" alt="The attachable VSM option" />
    <content>
      <p>
        The VSM measures magnetic properties of samples and using a motor
        (depicted to the right) mounted on top of the sample chamber. While this
        additional option can measure magnetic properties faster than the SQUID
        it does so with poorer resolution.
      </p>
      <p>
        When the VSM motor is attached to the system samples must be mounted in
        a magnetically neutral tube. During measurements the motor raises and
        lowers the sample in a linear path.
      </p>
    </content>

    <h4>Still curious?</h4>
    <content>
      <p>
        Here are a few of our research publications that have used this
        instrument!
      </p>
    </content>
    <content className="papers-container">
      <a href="https://www.mdpi.com/1996-1944/11/6/903">
        Enhancing Thermoelectric Properties through Control of Nickel
        Interstitials and Phase Separation in Heusler/Half-Heusler TiNi
        <sub>1.1</sub>Sn Composites
      </a>

      <a href="https://journals.aps.org/prmaterials/abstract/10.1103/PhysRevMaterials.2.124202">
        Nitrogen surface passivation of the Dirac semimetal Cd<sub>3</sub>As
        <sub>2</sub>
      </a>

      <a href="https://journals.aps.org/prb/abstract/10.1103/PhysRevB.98.155128">
        Sr<sub>3</sub>Ir<sub>2</sub>O<sub>7</sub>F<sub>2</sub>: Topochemical
        conversion of a relativistic Mott state into a spin-orbit driven band
        insulator
      </a>
    </content>
  </div>
);

const NonTechnical = () => (
  <div className="contentContainer">
    <h4>A Physical Property Measurement System</h4>

    <content>
      <img src={ppms} id="ppms" alt="The PPMS instrument" />

      <p>
        This physical property measurement system (PPMS) from Quantum Design is
        used to test the electrical and thermal properties of research
        materials.
      </p>

      <p>
        It can run these tests while changing its temperature to anywhere from{" "}
        <strong>400 K</strong> (260.33 °F, 127 °C) down to{" "}
        <strong>1.8 K</strong> (-456.43 °F, -271 °C). That's colder than space!
      </p>

      <p>
        Additionally the instrument uses a large superconducting magnet that can
        generate a field of up to <strong> 9 Tesla</strong>, or 90,000 gauss.
        For comparison a common fridge magnet can generate around 100 gauss!
      </p>
    </content>
    <h4>How it works</h4>
    <content>
      <img
        src={ppmsSampleChamber_noLabels}
        id="ppms-sampleChamber"
        alt="A diagram of the sample chamber and sample puck."
      />
      <p>
        Test materials are placed on a sample puck and connected by wires. After
        the sample and puck are connected they are lowered into the sample
        chamber and seated on a set of pins at the bottom.
      </p>

      <p>
        The sample chamber is then sealed and a gas pump pulls out all of the
        air, creating a <strong>vacuum</strong>. If any oxygen, water or other
        gases were present then they could interfere with the measurements or
        even freeze and damage the instrument.
      </p>

      <p>
        To keep the system cool we use two helium circulation loops. The inside
        of the instrument uses a<strong> low pressure loop</strong> which pulls
        heat out of the components. This heat is then pulled out of the low
        pressure loop by a <strong>pulse tube refrigerator</strong>, powered by
        a <strong>high pressure loop</strong>.
      </p>
    </content>
    <h4>The superconducting magnet</h4>
    <content>
      <p>
        The superconducting magnet sits inside of the machine and surrounds the
        sample chamber. Before it can become superconducting the magnet has to
        be extremely cold. A separate high pressure helium loop is used to power
        a second pulse tube cold head, keeping the magnet cool.
      </p>

      <h5>
        Look for: Large metal lines coming out of the back of the instrument and
        going towards the ceiling. These carry high pressure helium between the
        PPMS and a compressor which keeps the machine cool.
      </h5>

      <p>
        In order to generate a magnetic field the maget needs to be at a
        temperature of around 4.2 K (-452.11 °F, -269 °C) but when it starts to
        run it can generate heat. If it ever goes up by 0.8 K or more then it
        stops working and suddenly becomes resistant. This is known as{" "}
        <strong>magnet quenching</strong>.
      </p>
    </content>
    <h4>What does it measure?</h4>
    <ul>
      <li>Resistivity</li>
      <li>
        <a href="https://en.wikipedia.org/wiki/Hall_effect">
          Hall Coefficient (R<sub>H</sub>)
        </a>
      </li>
      <li>R vs. I</li>
      <li>Magnetic Moment</li>
      <li>I-V Curves</li>
      <li>
        <a href="https://en.wikipedia.org/wiki/Critical_field">
          Critical Current
        </a>
      </li>
      <li>dV/dI vs I</li>
    </ul>
    <h4>Still curious?</h4>
    <content>
      <p>Try some of these links or flip the "tech jargon" switch up top!</p>
      <p className="linkContainer">
        <a href={dynacoolMRLLink}>The MRL Dynacool Page</a>
        <a href={superMagnetsLink}>Superconducting Magnets</a>
        <a href={pulseTubeLink}>Pulse Tube Refrigerators</a>
      </p>
    </content>
  </div>
);

export default DynacoolPage;
